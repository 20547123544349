<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat dense dark color="secondary">
            <v-icon>fal fa-tag</v-icon>
            <v-toolbar-title class="pl-2">
              Payment Details
            </v-toolbar-title>
          </v-toolbar>

          <v-card flat color="accent">
            <v-card-text class="px-0">
              <v-container fluid grid-list-md class="pt-0 px-0">
                <!--Payment Details-->
                <v-layout row wrap>
                  <v-flex sx12>
                    <v-card flat height="100%">
                      <v-card-text>
                        <!--Company Name-->
                        <v-layout
                          v-if="usersStore.authUser.customers.length > 0"
                          row
                          wrap
                        >
                          <v-flex xs12 class="py-0">
                            <div
                              class="subtitle-1 grey--text text--darken-3 font-weight-bold"
                            >
                              {{ paymentsStore.payment.customer.d_companyName }}
                            </div>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap class="pt-4">
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="paymentsStore.payment.d_paymentNumber"
                              label="Payment Number"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="formatDate(paymentsStore.payment.d_date)"
                              label="Payment Date"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="'$' + paymentsStore.payment.d_totalAmount"
                              label="Amount"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="
                                paymentsStore.payment.payment_method.d_name
                              "
                              label="Method"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                          <v-flex xs6 md2>
                            <v-text-field
                              :value="
                                translatePaymentStatus(
                                  paymentsStore.payment.f_status
                                )
                              "
                              label="Status"
                              placeholder=" "
                              persistent-placeholder
                              flat
                              readonly
                              loading="false"
                              class="pb-0"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <!--  Line Items -->
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-toolbar flat>
                      <v-toolbar-title class="primary--text">
                        Invoices
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-list
                      v-if="paymentsStore.payment.invoices.length > 0"
                      two-line
                      class="pt-0"
                    >
                      <template
                        v-for="(item, i) in paymentsStore.payment.invoices"
                      >
                        <v-list-item :key="i" @click="goInvoice(item.uuid)">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.d_invoiceNumber }} -
                              {{ formatDate(item.d_date) }}
                            </v-list-item-title>
                            <v-list-item-title class="text--primary">
                              ${{ item.d_amountTotal }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              PO #{{ item.order.d_customerPurchaseOrder }} - Job
                              name {{ item.order.d_jobName }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text
                              :class="
                                'text-xs-right subtitle-1 ' +
                                  [
                                    item.f_status === 0
                                      ? 'green--text lighten-1--text'
                                      : 'primary--text'
                                  ]
                              "
                            >
                              {{ translateInvoiceStatus(item.f_status) }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";

export default {
  name: "PaymentDetail",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      paymentsStore: state => state.paymentsStore
    })
  },
  data() {
    return {
      progress: true
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const user = this.$store.getters["usersStore/getAuthUser"];

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        const uuid = this.$route.params.paymentUUID;
        await this.$store.dispatch("paymentsStore/readUUID", uuid);
        this.progress = false;
      }
    },

    goInvoice(uuid) {
      this.$router.push({
        name: "invoiceDetail",
        params: { invoiceUUID: uuid }
      });
    }
  }
};
</script>

<style scoped>
.lineItemContainer {
  height: 65%;
}
button {
  outline: none;
}
</style>
